.login-wrapper {
  max-width: 800px;
  min-width: 400px;

  .form-section {
    padding: 0 24px 24px;
    margin: 0 auto;
  }

  img.fgdemo-contact {
    width: 200px;
  }

  img.sportec-contact {
    width: 140px;
  }

  img.caferes-contact {
    width: 120px;
  }

  img.tso-contact {
    width: 150px;
  }
}