@import 'variables.scss';
@import 'layout/sidebar.scss';
@import 'layout/header.scss';
@import 'modal.scss';
@import 'login.scss';
@import 'badge.scss';
@import 'explain.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~react-datepicker/src/stylesheets/datepicker.scss';

.main-content {
  margin-left: $sidebar-w;
  .main-container {
    padding: 24px;
    box-shadow: 2px 0 4px 0 #ddd inset;
    height: calc(100vh - 32px);
    overflow: auto;
  }
}

.section {
  background-color: #fff;
  box-shadow: 0 2px 4px 2px #ddd;
  margin: 0 auto 2rem;
  padding: 24px;
  max-width: 600px;
  min-width: 450px;
  border-top: #8abd35 solid 4px;
  border-radius: 4px;

  &.wide-section {
    max-width: 1200px;
    min-width: 1000px;
  }
}

textarea.form-control {
  font-size: 13px;
}

.form-section {
  &.submit {
    text-align: center;
    padding-bottom: 0;
    .btn {
      width: 200px;
    }
  }
}

.btn {
  color: #fff;
  background-color: #06c;
  border-radius: 0;
  &.btn-secondary {
    color: #000;
    background-color: #ddd;
    &:hover {
      color: #888;
      background-color: #ddd;
    }
  }
  &:hover {
    color: #fff;
    background-color: #999;
    &:disabled {
      cursor: not-allowed;
      background-color: #06c;
    }
  }
  a {
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }
  &.loading {
    padding: 6px 4px;
    color: #fff;
    background-color: #999;
    cursor: default;
  }
  &.entry-btn {
    font-size: 24px;
    font-weight: bold;
    width: 400px;
  }
}

.btn-group {
  margin-bottom: 24px;
  justify-content: flex-end;

  &.btn-wrap {
    gap: 12px;
    flex-wrap: wrap;
  }

  display: flex;

  > .btn {
    flex: none;
  }
}

table {
  width: 100%;
  font-size: 18px;
  thead {
    tr {
      th {
        padding: 6px;
        font-weight: 400;
        &.badge-header {
          padding: 3px 5px;
          font-size: 75%;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        overflow-wrap: anywhere;
        padding: 8px 6px;
        &.tight-2 {
          width: 70px;
        }
        &.tight-3 {
          width: 90px;
        }
        &.tight-4 {
          width: 135px;
        }

        &.name {
          font-size: 20px;
        }
        &.mail-link-group {
          font-size: 13px;
          i.checked {
            vertical-align: baseline;
          }
        }
        .mail-is-notified {
          width: 20px;
          display: inline-block;
          text-align: center;
        }
        .mail-is-notified-no-width {
          // width: 20px;
          display: inline-block;
          text-align: center;
        }
        .mail-link {
          display: inline;
          color: #006fc0;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  tr {
    border-top: #000 solid 1px;
  }
}

i.checked {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-color: #06c;
  text-align: center;
  vertical-align: sub;
  &:before {
    font-size: 14px;
    color: #fff;
    content: '\02714';
    vertical-align: top;
  }
}
