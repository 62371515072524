.header{
  background-color: #404040;
  color: #FFF;
  padding: 2px 8px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  div {
    .page-title {
      padding-top: 2px;
    }
    .btn {
      border-radius: 2px;
      color: #000;
      background-color: #EEEAE5;
      font-size: 8px;
      padding: 2px 6px;
    }
  }
}
