.sidebar{
  text-align: center;
  width: $sidebar-w;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #FFF;
  overflow-y: auto;

  .logo{
    width: 120px;
    margin: 20px;
    margin-bottom: 0;
  }
}

.sidebar-wrapper{
  padding: 25px 0;
  height: 100%;
  min-height: 680px;
  position: relative;
}