.Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  overflow: auto;
  .update-modal, .mail-modal {
    width: 800px;
    max-height: 90vh;
    overflow: auto;
  }

  .mail-modal {
    .is-notified-group {
      display: flex;
      justify-content: space-between;
    }
    .modal-body {
      padding: 1rem 4rem;
    }
  }

  .modal-header {
    border: none;
  }
  .modal-footer {
    justify-content: center;
  }
}