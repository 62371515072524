.fgdemo {
  .badge-wrapper {
    .badge-front {
      position: absolute;
      top: 380px;
      left: 358px;
      line-height: 1.5;
    }
    ul {
      height: 158px;
      list-style: none;
      padding: 0;
      li {
        font-size: 28px;
        width: 480px;
        overflow-wrap: break-word;
        &.company-name {
          font-size: 28px;
          margin-top: 48px;
        }
      }
    }
    .badge-jp {
      margin-bottom: 32px;
    }
    font-weight: bold;
    line-height: normal;
    .badge-back {
      position: absolute;
      top: 920px; //with english 850px
      left: 358px;
      line-height: 1.5;
      transform: rotate(180deg);
    }
  }
}

.caferes2023, .sportec2023, .iloj2023, .wellness2023 {
  .badge-wrapper {
    #barcode_front {
      position: absolute;
      top: 225px;
      left: 420px;
    }
    .badge-front {
      position: absolute;
      top: 440px;
      left: 56px;
      line-height: 2;
    }
    ul {
      height: 158px;
      list-style: none;
      padding: 0;
      li {
        font-size: 28px;
        width: 480px;
        overflow-wrap: break-word;
        &.company-name {
          font-size: 28px;
          margin-top: 48px;
        }
      }
    }
    font-weight: bold;
    line-height: normal;
  }
  #badge {
    width: 1190px;
    height: 1683px;
  }
}